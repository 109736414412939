.character-correct {
  background-color: green;
}
.button-grid {
  background-image: linear-gradient(to top right, #16253b, #1e3047);
  border-radius: 10px;
  padding: 12px;
  max-width: 290px;
}
.button-grid .ui input, .button-grid .ui input:focus {
  background-color: #293c5e;
  color: #e0e0e0;
  font-size: 2.2em;
  font-family: "helvetica", "century gothic", "arial";
  margin: 6px;
  padding: 5px 10px;
}
.button-grid .ui.button {
  margin: 5px;
  font-family: "helvetica", "century gothic", "arial";
  color: white;
  background-color: #243b5a;
  font-size: 2.2em;
  padding: 20px 30px;
}
.button-grid .ui.button:active {
  opacity: .5;
}
.submit-button {
  width: 95%;
}
.code-box .code-character {
  border: #3e568a dashed 3px;
  font-size: 2.2em;
  padding: 15px 20px;
  margin: 10px 5px;
  display: inline-block;
}
.code-box .code-character {
  border: #3e568a solid 3px;
}
.guess-list .guess-row div {
  border: #3e568a dashed 3px;
  font-size: 2.2em;
  padding: 15px 20px;
  margin: 10px 5px;
  display: inline-block;
  vertical-align: middle;
}

.group-list-item {
  margin-top: 5px;
  margin-bottom: 5px;
}

