.themaze div.cell {
  padding: 2px;
}
.themaze section {
  border: 2px solid #ddd;
}
.themaze div.cell.wt { border-top: 2px solid #ddd; padding-top: 0;}
.themaze div.cell.wb { border-bottom: 2px solid #ddd; padding-bottom: 0;}
.themaze div.cell.wl { border-left: 2px solid #ddd; padding-left: 0;}
.themaze div.cell.wr { border-right: 2px solid #ddd; padding-right: 0;}

section {
  background: #999;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  /* padding: 20px; */
  max-width: 100vmin;
  max-width: 500px;
  /* margin: auto; */
}

.themaze hr {flex-basis: 100%; height: 0; margin: 0; border: 0; } 
.themaze p {
  width: 100%;
}
section div {
  background: #121212;
  /* background: #CCC; */
  /* min-width: 8%; */
  /* cannot be more than 4 on a row */
  flex-grow: 1;
  /* stretch their width evenly */
  /* margin: 1vmin; */
}

section div:before { 
  content: '';
  padding-top: 100%;
  float: left;
}

section i.maze-entity-15 {
  position: absolute;
  display: inline-flex;
  padding-top: calc(0px + .5vw);
  padding-left: calc(2px + .5vw);
  font-size: calc(6px + 1.7vw) !important;
}

section i.maze-entity-10 {
  position: absolute;
  display: inline-flex;
  padding-top: calc(2px + .8vw);
  padding-left: calc(2px + .8vw);
  font-size: calc(16px + 1vw) !important;
}
section i.maze-entity-5 {
  position: absolute;
  display: inline-flex;
  padding-top: calc(12px + 3vw);
  padding-left: calc(2px + 2.5vw);
  font-size: calc(32px + 2.5vw) !important;
}

div.button-controls {
  margin-left: calc(20px + 9vw);
}
button.ui.right-arrow, button.ui.left-arrow {
  position: absolute;
  margin-top: -35px;
}